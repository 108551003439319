import { template as template_932d9ccd51cd4ee1b9e771b9f772942b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_932d9ccd51cd4ee1b9e771b9f772942b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
