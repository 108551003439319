import { template as template_93df58d80d7b450b89d9f16fc6dd7010 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_93df58d80d7b450b89d9f16fc6dd7010(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
